<template>
  <div class="grow center wrap">
    <form @submit.prevent="post" class="container center d-col">
      <s-logo />
      <h1 v-if="$mq != 'sm'" class="d-flex green--text font-48">
        <div class="weight-300">secur</div>
        <div class="bold">Meet</div>
      </h1>
      <div class="missingText red--text" >
        <div v-if="message">
          {{message }}
        </div>
      </div>
      <s-text-field
        v-model="username"
        details
        block
        :error="userError"
        :label="$t('login.email')"
      ></s-text-field>
      <s-text-field
        v-model="password"
        type="password"
        block
        password
        details
        :error="passwordError"
        :label="$t('login.password')"
      ></s-text-field>
      <s-btn block class="green mt-15" height="40">
        {{ $t("login.submit") }}
      </s-btn>
      <div class="d-flex w100 space-between">
        <router-link
          class="green--text mt-15 font-14 d-flex center"
          to="lostpassword"
        >
          <div>{{ $t("login.forgot") }}</div>
        </router-link>
        <router-link
          class="green--text mt-15 font-14 d-flex center"
          to="/register"
        >
          <!-- <div>{{ $t("login.signup") }}</div> -->
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      message: "",
      userError: "",
      passwordError: "",
    };
  },
  methods: {
    async post() {
      let response = await this.$store.dispatch("auth/login", {
        email: this.username,
        password: this.password,
      });
      console.log("🚀 ~ response", response)
      this.userError = "";
      this.passwordError = "";
      this.message = "";

      if (this.username == "") this.userError = this.$t("login.enter_email");
      if (this.password == "") this.passwordError = this.$t("login.enter_password");
      if (response.status == "error" && !response.errorCode ) this.message = response.message
      if (response.status == "error" && response.errorCode ) this.message = this.$t("server." + response.errorCode)
      if (response.message == "Incorrect password.") this.passwordError = this.$t("server." + response.errorCode);
      if (response.message == "Incorrect email.") this.userError = this.$t("server." + response.errorCode);

      if (response.status == "ok") {
        await this.$store.dispatch("user/getUserInformation");
        this.$store.commit("calendar/loadData");
        this.$store.dispatch("rooms/getRoomsInformation");
        this.$store.dispatch("attendees/getAttendeesInformation");
        this.$store.dispatch("user/getTimezones");
        this.$store.dispatch("user/checkIfAccountIsOutOfTrial");
        this.$store.dispatch("calendar/innitGetUpcomingEvents");
        this.$store.dispatch("user/getConfiguration");
         this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped lang="less">
.container {
  width: 100%;
  max-width: 400px;
  margin: 10px;
}

.textRed {
  color: #df5151;
}
</style>